@font-face {
  font-family: 'Campton Semi Bold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Campton Semi Bold'),
    local('CamptonSemiBold'),
    url(./layout/fonts/CamptonSemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Campton Book';
  font-style: normal;
  font-weight: normal;
  src:
    local('Campton Book'),
    local('CamptonBook'),
    url(./layout/fonts/CamptonBook.woff) format('woff');
}

@font-face {
  font-family: 'Campton Medium';
  font-style: normal;
  font-weight: normal;
  src:
    local('Campton Medium'),
    local('CamptonMedium'),
    url(./layout/fonts/CamptonMedium.woff) format('woff');
}

@font-face {
  font-family: 'Campton Bold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Campton Bold'),
    local('CamptonBold'),
    url(./layout/fonts/CamptonBold.woff) format('woff');
}

@font-face {
  font-family: 'Campton Extra Bold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Campton Extra Bold'),
    local('CamptonExtraBold'),
    url(./layout/fonts/CamptonExtraBold.woff) format('woff');
}

body {
  font-family:
    'Campton Book',
    Arial,
    Helvetica,
    sans-serif;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #00A050 !important;
}
