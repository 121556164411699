.snackbar {
  position: relative;
  background: #193232;
  color: #fff;
  border-radius: 8px;
  width: 100%;
  max-width: 650px;
}

.ant-notification-bottomLeft {
  margin-left: 35%;
  margin-right: 3%;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  font-size: 15px;
  padding-right: 38px;
  color: #fff;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 19px;
}
